import { useCallback } from 'react';

import { toolList } from '@/pages/home/common/toolList';
import { useOverview } from '@/pages/overview/common/utils';
import { ToolPageCard } from '@/pages/tools/common/ToolPageCard';

export const ToolPageContent = () => {
  const { matter } = useOverview();

  const userSelectedTools = matter.tools.map((tool) => tool.toolType);
  const dataRoomTitle = matter.name;
  const checkIfToolSelected = useCallback(
    (toolIdentifier: string) => {
      if (userSelectedTools) {
        return userSelectedTools.includes(toolIdentifier);
      } else {
        return false;
      }
    },
    [userSelectedTools],
  );

  return (
    <div className="w-full px-[16px] pb-[20px] pt-[108px]">
      <h1 className="pb-[16px] text-[24px] text-marveri-white">Add Tools to {dataRoomTitle}</h1>

      <div className="grid grid-cols-[repeat(auto-fit,_minmax(230px,_1fr))] gap-3">
        {toolList.map((tool, index) => {
          return (
            <ToolPageCard
              key={index}
              toolName={tool.name}
              toolIcon={tool.icon}
              toolIdentifier={tool.toolIdentifier}
              toolReadyQuote={tool.toolReadyQuote}
              toolTip={tool.toolTip}
              path={tool.path}
              isToolAdded={checkIfToolSelected(tool.toolIdentifier)}
            />
          );
        })}
      </div>
    </div>
  );
};
