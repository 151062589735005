import { FileWithPath, fromEvent } from 'file-selector';
import { useCallback, useMemo, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';

import GenericUploadIcon from '@/assets/images/generic-upload-icon.svg';
import { FileValidationResult } from '@/common/types';
import { ACCEPTED_EXTENSIONS, ACCEPTED_TYPES, FILE_UPLOAD_ACCEPT_OBJECT } from '@/common/utils';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '@/constants';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';
import { DocumentSetUploaderList } from '@/pages/home/content/DocumentSetUploaderList';

const MAX_INT_32 = 2147483647;

interface DocumentSetUploaderProps {
  onUploadDone: (files: File[]) => void;
  onFileDelete: (fileToDelete: File) => void;
  totalCount: number;
}

export const DocumentSetUploader = ({
  onUploadDone,
  onFileDelete,
  totalCount,
}: DocumentSetUploaderProps) => {
  const MAX_FILES = 1000 - totalCount;

  const { newDiligenceData, updateNewDiligenceData } = useNewDiligenceWizard();

  const { dataRoomFiles } = newDiligenceData;

  const [dragging, setDragging] = useState(false);

  const [rejectedFiles, setRejectedFiles] = useState<FileValidationResult[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[] | undefined>(dataRoomFiles);

  const formatClientName = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };

    return date.toLocaleDateString('en-US', options);
  };

  const validateFile = (file: FileWithPath): FileValidationResult => {
    if (file.size > MAX_FILE_SIZE) {
      return { file, valid: false, reason: `File too large (${MAX_FILE_SIZE_MB}MB limit)` };
    }
    if (!file.size) {
      return { file, valid: false, reason: 'Empty file - try unzipping before dragging' };
    }
    if (!ACCEPTED_TYPES.includes(file.type)) {
      return { file, valid: false, reason: 'Wrong file type' };
    }
    return { file, valid: true };
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filesToUpload = [...(uploadedFiles || []), ...(acceptedFiles || [])];
      setDragging(false);
      setUploadedFiles((prevFiles) => {
        const files = Array.isArray(prevFiles) ? prevFiles : [];
        return [...files, ...acceptedFiles];
      });
      onUploadDone(acceptedFiles);
      const dateInMS = Date.now() % MAX_INT_32;
      updateNewDiligenceData({
        client: {
          name: formatClientName(new Date()),
          number: dateInMS,
        },
        name: formatClientName(new Date()),
        number: dateInMS,
        dataRoomFiles: filesToUpload,
        selectedTools: [...(newDiligenceData?.selectedTools || [])],
      });
    },
    [newDiligenceData?.selectedTools, onUploadDone, updateNewDiligenceData, uploadedFiles],
  );

  const getFilesOrFolders = async (event: DropEvent) => {
    const raw_files = await fromEvent(event);
    const files = raw_files.filter((file) => (file as FileWithPath).path !== undefined);
    const validatedFiles = files
      .slice(0, MAX_FILES * 10)
      .map((file) => validateFile(file as FileWithPath));

    const validFiles = validatedFiles.filter((result) => result.valid).map((result) => result.file);
    const rejectedFilesWithReasons = validatedFiles.filter((result) => !result.valid);

    setRejectedFiles((prevRejectedFiles) => [...prevRejectedFiles, ...rejectedFilesWithReasons]);
    return validFiles.slice(0, MAX_FILES);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: MAX_FILES,
    accept: FILE_UPLOAD_ACCEPT_OBJECT,
    getFilesFromEvent: (event) => getFilesOrFolders(event),
    noClick: true,
    noKeyboard: true,
    onDragLeave: () => setDragging(false),
  });

  const dragOverListener = useCallback(() => {
    setDragging(true);
  }, []);

  const fileFormatString = useMemo(() => {
    return ACCEPTED_EXTENSIONS.join(', ');
  }, []);

  return (
    <div className="relative mb-[24px] flex w-full flex-col" data-testid="dataroom-uploader">
      <div className="flex items-baseline justify-between">
        <h1 className="text-[20px] font-medium leading-[30px] text-marveri-white">Upload Files</h1>
        <div>
          {uploadedFiles === undefined ||
          (uploadedFiles.length === 0 && rejectedFiles.length === 0) ? (
            <div
              className="text-center text-[14px] font-medium text-marveri-light-silver"
              data-testid="dataroom-uploader-no-docs"
            >
              No files uploaded
            </div>
          ) : (
            <div className="flex">
              <h1 className="font-medium text-[#868686]">{`${uploadedFiles?.length} File${
                uploadedFiles?.length > 1 ? `s` : ''
              } Successfully Uploaded`}</h1>
              {rejectedFiles && rejectedFiles.length !== 0 && (
                <>
                  <span className="px-[5px] font-medium text-marveri-white">{`/`}</span>
                  <h1 className="font-medium text-marveri-red">{`${rejectedFiles.length} File${
                    rejectedFiles.length > 1 ? 's' : ''
                  } Rejected`}</h1>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-full pt-[16px]">
          {totalCount + rejectedFiles.length === 0 || isDragActive || dragging ? (
            <div
              {...getRootProps()}
              className={`relative flex h-[264px] cursor-not-allowed flex-col items-center justify-center rounded-lg border border-dashed border-dark-border shadow ${
                isDragActive ? 'border-blue-500 bg-container-selected' : 'bg-[#19181A] '
              }`}
            >
              <form>
                <input {...getInputProps()} />
              </form>
              <div className="flex flex-col items-center justify-center py-[10px]">
                <div className="flex flex-col items-center text-center">
                  <img src={GenericUploadIcon} alt="icon" />
                  <p className="p-2 pb-[7px] text-[18px] font-bold text-marveri-white">
                    Drag & Drop Files
                  </p>
                  <div>
                    <div>
                      <p className="text-[12px] font-normal text-N400">
                        Supported file formats: <br />{' '}
                        <span className="font-semibold">{fileFormatString}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div onDragOver={dragOverListener}>
              <DocumentSetUploaderList
                onFileDelete={onFileDelete}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                rejectedFiles={rejectedFiles}
                setRejectedFiles={setRejectedFiles}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
