import { useCallback, useEffect, useState } from 'react';

import { useDataRoom } from '@/contexts/overview/dataroom/utils';

interface RenameListItemFormProps {
  onClose: () => void;
  onSubmit: (newName: string) => void;
  currentName: string;
  renameInputRef?: React.RefObject<HTMLInputElement>;
  setRenameFileIndex?: (val: number) => void;
}

export const RenameListItemForm = ({
  onClose,
  onSubmit,
  currentName,
  renameInputRef,
  setRenameFileIndex,
}: RenameListItemFormProps) => {
  const [newListItemName, setNewListItemName] = useState(currentName);

  const { combinedDocumentArray } = useDataRoom();

  const getCurrentFileIndex = useCallback(
    (currentFileName: string) => {
      const currentFileIndex = combinedDocumentArray.findIndex(
        (dataRoomFile) => dataRoomFile.displayName === currentFileName,
      );
      if (setRenameFileIndex) {
        setRenameFileIndex(currentFileIndex);
      }
    },
    [combinedDocumentArray, setRenameFileIndex],
  );

  useEffect(() => {
    if (currentName) {
      getCurrentFileIndex(currentName);
      if (renameInputRef && renameInputRef.current) {
        renameInputRef.current.focus();
      }
    }
  }, [currentName, getCurrentFileIndex, renameInputRef]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewListItemName(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (event.key === 'Escape') {
      onClose();
    }
  };

  return (
    <form
      className={`flex w-full items-center justify-between rounded-[5px] border border-[#4E9FFF] bg-marveri-background text-marveri-white`}
      onSubmit={() => onSubmit(newListItemName)}
      onClick={(e) => e.stopPropagation()}
      data-tesid="rename-input-form"
    >
      <input
        type="text"
        className={`h-[35px] w-full rounded-[5px] bg-inherit indent-2 outline-none`}
        onChange={handleChange}
        value={newListItemName}
        onKeyDown={handleKeyDown}
        ref={renameInputRef}
      />
    </form>
  );
};
