import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useState } from 'react';

import { HoverText } from '@/components/HoverText';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useOverview } from '@/pages/overview/common/utils';

interface DropdownItems {
  icon?: string;
  title: string;
  onClick: () => void;
}

interface DropdownProps {
  icon?: string;
  title: string;
  testId: string;
  options?: DropdownItems[];
  processing?: boolean;
  isButtonDisabled: boolean;
  bgColor?: string;
  resizeAction?: boolean;
}

export const Dropdown = ({
  icon,
  title,
  testId,
  options,
  processing,
  isButtonDisabled,
  bgColor,
  resizeAction,
}: DropdownProps) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const { isPanelCollapsed } = useOverview();

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div
        className="relative flex justify-center"
        onMouseEnter={() => setIsButtonHovered(true)}
        onMouseLeave={() => setIsButtonHovered(false)}
      >
        <Menu.Button
          aria-disabled={isButtonDisabled}
          className={`${bgColor} ${
            isButtonDisabled ? 'cursor-default bg-N700 opacity-80' : bgColor ? '' : 'hover:bg-N700'
          } relative flex shrink-0 justify-center gap-x-2 rounded-[4px] bg-container-default px-3 py-2 font-bold text-marveri-white shadow-sm`}
          data-testid={testId}
          onKeyDown={handleKeyDown}
        >
          {icon ? (
            processing ? (
              <LoadingSpinner />
            ) : (
              <img src={icon} className={`${isButtonDisabled && 'opacity-50'} size-[16px]`} />
            )
          ) : (
            <></>
          )}
          {title && (
            <span
              className={`${resizeAction && isPanelCollapsed ? 'tool-resize-1-panel:hidden' : 'tool-resize-1:hidden'} ${
                isButtonDisabled && 'text-[rgba(234,234,234,0.5)]'
              }`}
            >
              {title}
            </span>
          )}
        </Menu.Button>
        {resizeAction ? (
          <HoverText
            isButtonHovered={isButtonHovered}
            title={title}
            isDisabled={isButtonDisabled}
            isLoading={processing}
          />
        ) : (
          ''
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${
            bgColor ? 'z-20 border-none' : 'z-20'
          } absolute right-0 z-10 w-[200px] origin-top-right rounded-md border-2 border-light-border bg-container-dark text-xs font-normal text-marveri-white shadow-lg drop-shadow-[4px_4px_2px_rgba(0,0,0,0.05)] focus:outline-none`}
        >
          <div className="py-0">
            {options &&
              options.map((option, index) => (
                <div key={option.title}>
                  <Menu.Item key={option.title}>
                    <span
                      className={`${bgColor} block cursor-pointer justify-center rounded-md border-b border-container-hover bg-container-dark px-4 py-2 text-sm font-normal text-marveri-white shadow-lg hover:bg-container-hover focus:outline-none`}
                      onClick={option.onClick}
                      data-testid={`${testId}-${index}`}
                    >
                      {icon && (
                        <img
                          src={option.icon}
                          className="mx-[10px] my-[3px] inline-block size-[16px]"
                        />
                      )}
                      {option.title}
                    </span>
                  </Menu.Item>
                </div>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
