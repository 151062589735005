import { Dialog, DialogPanel } from '@headlessui/react';
import { useCallback, useRef, useState } from 'react';

import Xicon from '@/assets/icons/gray-x-icon.svg';
import { User } from '@/common/types';
import { MarveriIcon } from '@/pages/overview/common/MarveriIcon';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { NamingTemplate } from '@/pages/settings/namingTemplate/NamingTemplate';
import { Profile } from '@/pages/settings/profile/Profile';
import { Security } from '@/pages/settings/security/Security';
import { UnsavedChangesModal } from '@/pages/settings/UnsavedChangesModal';

interface ActiveSettingsTabProps {
  tabName: string;
  focusedTab: string;
  setFocusedTab: (val: string) => void;
  onClick: (val: string) => void;
}

export const ActiveSettingsTabLink = ({
  tabName,
  focusedTab,
  setFocusedTab,
  onClick,
}: ActiveSettingsTabProps) => {
  const handleTabClick = () => {
    setFocusedTab(tabName);
    onClick(tabName);
  };
  return (
    <div
      className={`${tabName === focusedTab && ' bg-N600'} h-[60px] w-[240px] cursor-pointer rounded-[8px] px-[16px] py-[20px] font-semibold text-[#EAEAEA] hover:bg-N700`}
      onClick={handleTabClick}
      data-testid="active-settings-tab"
    >
      {tabName}
    </div>
  );
};

interface SettingProps {
  isSettingsModalOpen: boolean;
  setIsSettingModalOpen: (val: boolean) => void;
  user: User;
}

export const Settings = ({ user, isSettingsModalOpen, setIsSettingModalOpen }: SettingProps) => {
  const settingsNavigation = [
    {
      tab: 'Profile Settings',
      path: 'profile',
    },
    { tab: 'Document Naming', path: 'doc-naming' },
    {
      tab: 'Security & Privacy',
      path: 'security-privacy',
    },
  ];

  const [focusedTab, setFocusedTab] = useState('Profile Settings');
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] = useState(false);
  const [canClose, setCanClose] = useState(true);

  const profileRef = useRef<HTMLDivElement>(null);
  const docNamingRef = useRef<HTMLDivElement>(null);
  const securityRef = useRef<HTMLDivElement>(null);

  const scrollToTab = (tabName: string) => {
    if (tabName === 'Profile Settings' && profileRef.current)
      profileRef.current.scrollIntoView({ behavior: 'smooth' });
    if (tabName === 'Document Naming' && docNamingRef.current)
      docNamingRef.current.scrollIntoView({ behavior: 'smooth' });
    if (tabName === 'Security & Privacy' && securityRef.current)
      securityRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const canCloseCallback = useCallback((newCanClose: boolean) => {
    setCanClose(newCanClose);
  }, []);

  const checkIfSettingsCanClose = () => {
    if (!canClose) {
      setIsUnsavedChangesModalOpen(true);
      return;
    }
    setIsSettingModalOpen(false);
  };

  return (
    <Dialog
      open={isSettingsModalOpen}
      onClose={checkIfSettingsCanClose}
      className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto bg-black/50"
      data-testid="settings-page-modal"
    >
      <DialogPanel className="max-h-[627px] min-h-[352px] w-[70%] overflow-y-auto rounded-lg bg-[#121212] py-[32px] pr-[32px] text-marveri-white">
        <div className="flex justify-between pl-[32px]">
          <h1 className="text-[30px] font-semibold ">Settings</h1>
          <MarveriIcon
            icon={Xicon}
            onClick={checkIfSettingsCanClose}
            iconStyle=""
            iconType="other"
          />
        </div>
        <div className="flex w-full">
          <div className="flex flex-col gap-[4px] px-[18px] py-[32px]">
            {settingsNavigation.map((settingNav, index) => {
              return (
                <ActiveSettingsTabLink
                  key={index}
                  tabName={settingNav.tab}
                  focusedTab={focusedTab}
                  setFocusedTab={setFocusedTab}
                  onClick={() => scrollToTab(settingNav.tab)}
                />
              );
            })}
          </div>
          <ScrollableDiv containerStyle="flex flex-col w-full medium:px-[18px] px-[60px] h-[calc(30vh+100px)]">
            <div ref={profileRef}>
              <Profile />
            </div>
            <div className="my-[48px] w-full border border-[#303030]"></div>
            <div ref={docNamingRef}>
              <NamingTemplate user={user} setCanClose={canCloseCallback} />
            </div>
            <div className="my-[48px] w-full border border-[#303030]"></div>
            <div ref={securityRef}>
              <Security />
            </div>
          </ScrollableDiv>
        </div>
      </DialogPanel>
      <UnsavedChangesModal
        isUnsavedChangesModalOpen={isUnsavedChangesModalOpen}
        setIsUnsavedChangesModalOpen={setIsUnsavedChangesModalOpen}
        setIsSettingModalOpen={setIsSettingModalOpen}
      />
    </Dialog>
  );
};
