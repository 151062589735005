import LogRocket from 'logrocket';
import { useCallback, useEffect, useState } from 'react';

import ArrowIcon from '@/assets/icons/rounded-arrow-icon.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { ENVIRONMENT } from '@/constants';
import { useNewDiligenceWizard } from '@/contexts/create-new-diligence-wizard/utils';
import { HomeHoverText } from '@/pages/home/common/HomeHoverText';
import { HomeSidebar } from '@/pages/home/common/HomeSidebar';
import { MarveriButton } from '@/pages/home/common/MarveriButton';
import { ToolCard } from '@/pages/home/common/ToolCard';
import { toolList } from '@/pages/home/common/toolList';
import { DocumentSetUploader } from '@/pages/home/content/DocumentSetUploader';
import { ScrollableDiv } from '@/pages/overview/common/ScrollableDiv';
import { trpcReact } from '@/utils/trpc';

export const HomeContent = () => {
  const {
    updateNewDiligenceData,
    newDiligenceData,
    handleAddOrRemoveTool,
    setUploadProcessing,
    uploadProcessing,
    finishDataRoomUpload,
  } = useNewDiligenceWizard();
  useEffect(() => {
    localStorage.setItem('folderOpenMap', '');
  }, []);

  const [files, setFiles] = useState<File[] | undefined>(newDiligenceData.dataRoomFiles);
  const [isButtonHovered, setIsButttonHovered] = useState(false);

  const user = trpcReact.user.getCurrentUser.useQuery().data;

  useEffect(() => {
    if (user && user.email && ENVIRONMENT === 'production') {
      LogRocket.identify('user', {
        name: user?.firstName + ' ' + user?.lastName,
        email: user?.email || 'Unknown',
      });
    }
  }, [user]);

  const addFiles = useCallback((newFiles: File[]) => {
    setFiles((files) => {
      if (files) {
        return [...files, ...newFiles];
      } else {
        return newFiles;
      }
    });
  }, []);

  const deleteFiles = useCallback(
    (fileToDelete: File) => {
      const filteredFileList = files?.filter(
        (currentFile) => currentFile.name !== fileToDelete.name,
      );
      setFiles(filteredFileList);
      updateNewDiligenceData({ dataRoomFiles: filteredFileList });
    },
    [files, updateNewDiligenceData],
  );

  const checkIfToolSelected = useCallback(
    (toolIdentifier: string) => {
      const userSelectedTools = newDiligenceData.selectedTools;
      if (userSelectedTools) {
        return userSelectedTools.includes(toolIdentifier);
      } else {
        return false;
      }
    },
    [newDiligenceData.selectedTools],
  );

  const handleSubmit = () => {
    localStorage.setItem('renameDocumentSetActive', 'false');
    setUploadProcessing(true);
    finishDataRoomUpload();
  };

  const totalNumberOfFiles = files?.length || -1;
  const toolCount = newDiligenceData.selectedTools?.length || -1;

  const handleSelectOrDeselectAll = () => {
    if (toolCount < toolList.length) {
      const allTools: string[] = [];
      toolList.forEach((tool) => {
        allTools.push(tool.toolIdentifier);
      });
      updateNewDiligenceData({ selectedTools: allTools });
    } else {
      updateNewDiligenceData({ selectedTools: [] });
    }
  };

  return (
    <div
      className={`relative z-[1] flex size-full bg-container-default py-[12px] pr-[12px] text-marveri-white`}
      data-testid="home-component"
    >
      <HomeSidebar />
      <div className="size-full cursor-default rounded-[12px] bg-marveri-background px-[16px] pb-[16px] pt-[24px] text-center">
        <h1 className="mb-[24px] text-[32px] font-semibold text-marveri-white">
          Welcome to Marveri, {user?.firstName}
        </h1>
        <DocumentSetUploader
          onUploadDone={addFiles}
          onFileDelete={deleteFiles}
          totalCount={files?.length || 0}
        />
        <div className="mb-[16px] flex items-center justify-between">
          <div className="flex items-center gap-[12px]">
            <h1 className="text-left text-[20px]">Enable Tools</h1>
            <div
              className="flex h-[34px] cursor-pointer items-center justify-center rounded-[32px] border border-N300 p-[12px] text-[14px] font-semibold hover:bg-[#292829]"
              onClick={handleSelectOrDeselectAll}
            >
              {toolCount < toolList.length ? 'Select all' : 'Deselect all'}
            </div>
          </div>
          <MarveriButton
            disabled={totalNumberOfFiles < 0 || toolCount < 0 || uploadProcessing}
            onClick={handleSubmit}
            className="group"
          >
            {uploadProcessing ? (
              <LoadingSpinner />
            ) : (
              <div
                className="relative flex items-center gap-[4px]"
                onMouseEnter={() => setIsButttonHovered(true)}
                onMouseLeave={() => setIsButttonHovered(false)}
              >
                <span>Create Project</span>
                <img
                  src={ArrowIcon}
                  className={`${totalNumberOfFiles > 0 && toolCount > 0 ? 'filter-dark' : ''} size-[12px] transition-all duration-300 ease-in-out`}
                />
                <HomeHoverText
                  isButtonHovered={isButtonHovered}
                  toolCount={toolCount}
                  fileCount={totalNumberOfFiles}
                />
              </div>
            )}
          </MarveriButton>
        </div>
        <ScrollableDiv containerStyle="h-[calc(100vh-530px)] pr-[4px]">
          <div className="grid grid-cols-[repeat(auto-fit,_minmax(220px,_1fr))] gap-3">
            {toolList.map((tool, index) => {
              return (
                <ToolCard
                  key={index}
                  toolName={tool.name}
                  toolIcon={tool.icon}
                  toolIdentifier={tool.toolIdentifier}
                  toolTip={tool.toolTip}
                  isToolAdded={checkIfToolSelected(tool.toolIdentifier)}
                  addToolFunction={handleAddOrRemoveTool}
                />
              );
            })}
          </div>
        </ScrollableDiv>
      </div>
    </div>
  );
};
